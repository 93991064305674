import PropTypes from 'prop-types';
import React from 'react';

import { home as pageMeta } from '../../data/page_meta.yml';
import { siteMeta } from '../../data/site_meta.yml';

import Results from '../results';
import {Hero} from '@brandedholdings/react-components';

class PersonalLoansResults extends React.Component {
    getChildContext() {
        return { siteMeta, pageMeta };
    }

    componentDidMount() {
        if (window.OneSignal && typeof window.OneSignal.isOptedOut === 'function') {
            window.OneSignal.isOptedOut().then(isOptedOut => {
                if (isOptedOut) {
                    return;
                }

                window.OneSignal.isPushNotificationsEnabled().then(isEnabled => {
                    if (!isEnabled) {
                        window.OneSignal.showHttpPrompt();
                    }
                });
            });
        }
    }

    render() {
        return (<Results
                ppc={true}
                hero={(
                    <div className="background background--personal">
                        <Hero
                            heading="Lending Options For Everyone"
                            subheading="Personal Loans, Credit Cards, & More!"
                            description={(<ul className="list"><li>Loans Up To $5,000</li><li>Credit Cards For All Credit Types</li><li>Compare & Find The Best Option</li></ul>)}
                            actionText={ null }
                            actionLink={ "#" }
                            heroModifiers="hero--arrow"
                            actionModifiers={ null }
                        />
                    </div>
                )}
        />);
    }
};

PersonalLoansResults.childContextTypes = {
    siteMeta: PropTypes.object,
    pageMeta: PropTypes.object
};

export default PersonalLoansResults;
